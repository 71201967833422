import React,{useRef,useEffect} from "react"
import styled from "styled-components"
import { Button } from "../../../reusableComponents/Button"
import mockup from "../../../../assets/images/services-mockup-7.png"
import fb from "../../../../assets/images/fb.png"
import youtube from "../../../../assets/images/youtube.png"
import amazon from "../../../../assets/images/amazon-icon.png"
import barnesAndNoble from "../../../../assets/images/b-and-noble-icon.png"
import bookStack from "../../../../assets/images/book-stack.png"
import website from "../../../../assets/images/website.png"
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import { gsap } from "gsap"

gsap.registerPlugin(ScrollTrigger)

const Services = () => {
  let container = useRef(null)

  useEffect(() => {
    const title = container.firstElementChild
    const content = container.lastElementChild

    const image = content.children[0]
    const services = content.children[1]

    gsap.to(container, { duration: 0, css: { visibility: "visible" } })

    let tl = new gsap.timeline({
      scrollTrigger:{
        trigger: container,
        start:"-60px 70%",
        end:"+=350px",
        scrub:2,
      }
    })
    
    tl.from(
      [title],
      {
        duration: 1,
        opacity: 0,
        y: -60,
        ease: "power3.inOut"
      },
      "start"
    )
    tl.from(
      [image],
      {
        duration: 1,
        opacity: 0,
        x: -50,
        ease: "power3.inOut"
      },
      "start"
    )
    tl.from(
      [services.children[0], services.children[1], services.children[2]],
      {
        duration: 1,
        opacity: 0,
        x: 60,
        ease: "power3.inOut",
        stagger: .15,
      },
      "start"
    )
  }, []);

  return (
    <Container className="flex-column" ref={el => container = el}>
      <Section className="flex-column ai-c jc-c gap-half">
        <Header>Services</Header>
        <Paragraph center={true}>Embark on a Journey through our Diverse Range of Specialized Services,<br/> Crafted with Your Unique Needs in Mind.</Paragraph>
      </Section>
      <ContentSection className="grid gap-4">
        <Wrapper>
          <ImageWrapper>
            <Image src={mockup} />
          </ImageWrapper>
          <IconsContainer>
            {/* <IconWrapper href="https://www.facebook.com/profile.php?id=61555616772326" target="_blank" rel="noopener noreferrer">
              <Image src={fb}/>
            </IconWrapper>
            <IconWrapper href="https://www.youtube.com/@WritersWaySolutions." target="_blank" rel="noopener noreferrer">
              <Image src={youtube}/>
            </IconWrapper> */}
            {/* <IconWrapper href="https://www.facebook.com/profile.php?id=61555616772326" target="_blank" rel="noopener noreferrer">
              <Image src={amazon}/>
            </IconWrapper>
            <IconWrapper small={true} href="https://www.facebook.com/profile.php?id=61555616772326" target="_blank" rel="noopener noreferrer">
              <Image src={barnesAndNoble}/>
            </IconWrapper> */}
          </IconsContainer>
        </Wrapper>
        <Wrapper className="flex-column ai-fs gap-2"> 
          <Wrapper className="flex-column ai-fs gap-1">
            <ImageWrapper small={true}>
              <Image src={bookStack} />
            </ImageWrapper>
            <Title>Publishing Services</Title>
            <Paragraph>Writers Way Solutions has a stellar history in-licensing of books to overseas publishers. We are regular participants at the Bologna, London, and Frankfurt Book Fairs. We work with co-agents in every country that publishes translations of English language books, many of whom we've been doing business with since we've begun. We are dedicated to endorsing and supporting our clients directly in the U.S., Europe, Africa, Australia, and Asia.</Paragraph>
            <Wrapper className="flex-column ai-fs">
              <Button round="true" to="/publishing-services">Learn more</Button>
            </Wrapper>
          </Wrapper>
          <Wrapper className="flex-column ai-fs gap-1">
            <ImageWrapper small={true}>
              <Image src={website} />
            </ImageWrapper>
            <Title>Marketing Services</Title>
            <Paragraph>At Writers Way Solutions, we understand that in today's digital landscape, effective marketing is essential for businesses to thrive. Our comprehensive suite of marketing services is designed to elevate your brand, engage your audience, and drive measurable results.</Paragraph>
            <Wrapper className="flex-column ai-fs">
              <Button round="true" to="/marketing-services">Learn more</Button>
            </Wrapper>
          </Wrapper>
          
        </Wrapper>
      </ContentSection>
    </Container>
  )
}

export default Services

const Container = styled.div`
  visibility: hidden;
  padding: 5rem calc((100vw - 1200px) / 2);
  ${'' /* background: #D95744; */}
  background: #f3f5fb;
  height: 100%;
  gap: 4rem;
  ${'' /* background-image: 
    radial-gradient(rgb(224, 87, 68) 40.4%, transparent 40.4%),
    radial-gradient(rgb(224, 87, 68) 40.4%, transparent 40.4%);
  background-position: 0px 0px, 4px 4px;
  background-size: 8px 8px;
  background-color: rgb(217, 87, 68); */}

  @media screen and (max-width: 1200px) {
    padding: 5rem 2rem;
    gap: 2rem;
  }
  @media screen and (max-width: 768px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`
const Paragraph = styled.p`
  font-size: clamp(0.7rem, 5vw, 0.9rem);
  color: rgb(112, 113, 117);
  line-height: 1.5;
  text-align: ${(props) => (props.center ? 'center' : 'justify')};

  @media screen and (max-width: 768px) {
    br {
      display: none;
    }
  }
`


const Section = styled.div`
  width: 100%;
`;

const ContentSection = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(550px, 1fr));
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 1200px) {
    gap: 2rem;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Header = styled.h3`
  text-align: start;
  font-size: clamp(3rem, 5vw, 2rem);
  font-weight: 700;
  color: ${props => props.theme.colors.accent5};
 `

 const Title = styled.h6`
    font-size: clamp(2rem, 2.5vw, 1.3rem);
    color: ${props => props.theme.colors.accent7};
    font-weight: 700;
 `

const Wrapper = styled.div`
 display: flex;
`;

const ImageWrapper = styled.div`
  width: ${(props) => (props.small ? '40px' : '100%')};
  height: ${(props) => (props.small ? '40px' : '100%')};
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  margin-left: -4rem;
  margin-top: 4.3rem;

  @media screen and (max-width: 768px) {
    margin-top: 1.4rem;
  }

  @media screen and (max-width: 568px) {
    margin-left: -3.2rem;
  }

  @media screen and (max-width: 425px) {
    gap: .3rem;
    margin-top: 1rem;
    margin-left: -2.5rem;
  }
`;
const IconWrapper = styled.a`
  width: ${(props) => (props.small ? '35px' : '40px')};
  height: ${(props) => (props.small ? '35px' : '40px')};
  cursor: pointer;

  @media screen and (max-width: 568px) {
    width: ${(props) => (props.small ? '25px' : '30px')};
    height: ${(props) => (props.small ? '25px' : '30px')};
  }
`;

// const Image = styled(GatsbyImage)`
//   height: 100%;
//   width: 100%;
//   ${'' /* border-radius: 50%; */}
//   -o-object-fit: contain;
//   object-fit: contain;
// `

export const Image = styled.img`
  width:100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  overflow: visible;

  @media screen and (max-width: 1200px) {
    width: 70%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

import styled from "styled-components"

export const Container = styled.div`
  visibility:hidden;
  display: flex;
  padding: 5rem calc((100vw - 1300px) / 2);
  justify-content: center;
  align-items: center;
  ${'' /* background:#f3f5fb; */}
  ${'' /* background: #1A5371; */}
  overflow:hidden;
  background-image: 
    radial-gradient(rgb(27, 84, 130) 40.4%, transparent 40.4%),
    radial-gradient(rgb(27, 84, 130) 40.4%, transparent 40.4%);
  background-position: 0px 0px, 4px 4px;
  background-size: 8px 8px;
  background-color: ${props => props.theme.colors.accent7};
  ${"" /* text-align:center; */}
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`
export const Wrapper = styled.div`
  width: 100%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Header = styled.div`
 display:flex;
 flex-direction:column; 
  margin-bottom: 1.5rem;
`

export const Title = styled.h3`
  font-size: clamp(1rem, 5vw, 2rem);
  font-weight: 700;
  color: ${props => props.theme.colors.accent6};
  ${'' /* color: #6dc0e3; */}
  margin-bottom:1rem;
`
export const Description = styled.p`
font-size: clamp(0.7rem, 5vw, 0.9rem);
  ${'' /* color: ${props => props.theme.colors.light1}; */}
  ${'' /* font-family: 'Roboto', sans-serif; */}
  color: #FFF;
  line-height:1.5;
`

export const Card = styled.div`
 display:flex;
 margin-bottom:1.5rem;
 width: 100%;
`

export const CardIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  margin-right:1rem;
`
export const CardWrapper = styled.div`
 display:flex;
 flex-direction:column;
`
export const CardTitle = styled.p`
 font-size: clamp(1rem, 2.5vw, 1.3rem);
  margin-bottom: 0.5rem;
  ${'' /* color: ${props => props.theme.colors.dark1}; */}
  color: #6dc0e3;
  font-weight: 700;
`
export const CardDescription = styled.p`
${'' /* font-family: 'Roboto', sans-serif; */}
  ${'' /* color: ${props => props.theme.colors.light1}; */}
  color: #FFF;
  font-size: clamp(0.5rem, 5vw, 0.9rem);
  line-height:1.5;
  width: 100%;
  text-align: justify;
`

export const Image = styled.img`
  ${"" /* height:100%; */}

  width:50%;
  padding: 0 2rem;
  padding-top: 2rem;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: visible;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin-bottom:2rem;
  }
`

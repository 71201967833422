import React,{useEffect,useRef} from "react"
import {
  WcuContainer,
  Header,
  Title,
  Description,
  Body,
  Cards,
  CardIcon,
  CardTitle,
  CardDescription,
} from "./WcuElements"
import { FcGlobe } from "@react-icons/all-files/fc/FcGlobe"
import { FcSalesPerformance } from "@react-icons/all-files/fc/FcSalesPerformance"
import { FcPositiveDynamic } from "@react-icons/all-files/fc/FcPositiveDynamic"
import { FcCustomerSupport } from "@react-icons/all-files/fc/FcCustomerSupport"
// ,FcApproval,FcCheckmark,FcGlobe,FcComboChart,FcDebt,FcPositiveDynamic,FcSalesPerformance,FcShipped,FcShop
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import { gsap } from "gsap"

gsap.registerPlugin(ScrollTrigger)

function Wcu() {
  let container = useRef(null)

  useEffect(() => {
 
    const header = container.firstElementChild
    const body = container.lastElementChild

       gsap.to(container, { duration: 0, css: { visibility: "visible" } })

       let tl = new gsap.timeline({
         scrollTrigger:{
           trigger: container,
           start:"20px 80%",
           end:"+=350px",
           scrub:2,
         }
       })

       tl.from(
        [header.children[0], header.children[1],body.children[0],body.children[1],body.children[2],body.children[3]],
        {
          duration: 1,
          opacity: 0,
          y: -50,
          ease: "power3.inOut",
          stagger: .15,
        },
      )
 
 },[])
  return (
    <WcuContainer ref={el => container = el}>
      <Header>
        <Title>Why choose us?</Title>
        <Description>
        We work with writers to help achieve their goals.
        </Description>
      </Header>
      <Body>
        <Cards>
          <CardIcon>
            <FcGlobe />
          </CardIcon>
          <CardTitle>Global Opportunities</CardTitle>
          <CardDescription>
          We strategically create global opportunities to widen the reach and strengthen your brand for potential book buyers and investors.

          </CardDescription>
        </Cards>
        <Cards>
          <CardIcon>
            <FcSalesPerformance />
          </CardIcon>
          <CardTitle>Commercialism
</CardTitle>
          <CardDescription>
          We help drive commerciality and global trading activities.

          </CardDescription>
        </Cards>
        <Cards>
          <CardIcon>
            <FcCustomerSupport />
          </CardIcon>
          <CardTitle>Transparency</CardTitle>
          <CardDescription>
          With years of experience, we have mastered the art of listening to the challenges our clients face and provide effective realistic solutions.

          </CardDescription>
        </Cards>
        <Cards>
          <CardIcon>
            <FcPositiveDynamic />
          </CardIcon>
          <CardTitle>Results Oriented</CardTitle>
          <CardDescription>
          We are results-driven. We make sure that our clients will have the best experience.

          </CardDescription>
        </Cards>
      </Body>
    </WcuContainer>
  )
}

export default Wcu

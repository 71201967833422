import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { Button } from "../../../reusableComponents/Button.js"
import img from "../../../../assets/images/type-writer.png"
import Typewriter from "typewriter-effect"
import { AiFillPlayCircle } from "@react-icons/all-files/ai/AiFillPlayCircle"
import { Link } from "gatsby"
import { gsap } from "gsap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import LeadPopup from "../../../reusableComponents/LeadPopup.jsx"

function Hero() {
  const [popUp, setPopup] = useState(false)
  const container = useRef(null)

  // Open the LeadPopup on component mount
  useEffect(() => {
    setPopup(true) // Set popUp to true to open the modal
    gsap.to(container.current, { delay: 1, css: { visibility: "visible" } })
  }, [])

  const data = useStaticQuery(graphql`
    query homeHeroQuery {
      allFile(
        filter: {
          ext: { regex: "/(jpg)|(png)|(jpeg)/" }
          name: { in: ["type-writer"] }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                transformOptions: { cropFocus: CENTER }
                placeholder: BLURRED
                quality: 100
                blurredOptions: { width: 10 }
              )
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <LeadPopup open={popUp} onClose={() => setPopup(false)} />
      <Container ref={container}>
        <Wrapper gap={"1.5rem"}>
          <CardOne>
            <HeaderTitleNormal>
              Your Story’s
              <br /> Journey
              <br /> is on the
            </HeaderTitleNormal>
            <HeaderTitleBig>Write Way</HeaderTitleBig>
            <Description>
              Welcome to Writer's Way, your ultimate destination for all things
              writing. Whether you're a seasoned wordsmith or just starting out
              on your creative journey, this website is designed to inspire,
              educate, and connect writers from all walks of life.
            </Description>

            <ActionWrapper>
              <ButtonContainer>
                <Button round="true" to="/contact" dark small>
                  Contact Us
                </Button>

                <IconWrapper
                  to="/book-trailers"
                  css={`
                    cursor: pointer;
                  `}
                >
                  <Wrapper width={"42px"} height={"42px"} isRow>
                    <AiFillPlayCircle
                      color={"#2B2B2B"}
                      css={`
                        width: 100%;
                        height: 100%;
                      `}
                    />
                  </Wrapper>
                  <span
                    css={`
                      color: #3d3f3f;
                      font-size: 14px;
                      font-weight: 400;

                      @media screen and (max-width: 568px) {
                        font-size: 10px;
                      }
                    `}
                  >
                    Book Trailers
                  </span>
                </IconWrapper>
              </ButtonContainer>
            </ActionWrapper>
          </CardOne>
          <CardTwo>
            <HeaderTitleTwo>
              Want to know more?
              <br /> Our Story Awaits You
            </HeaderTitleTwo>
            <DescriptionTwo>
              Join us as we share our story, values, and the
              <br
                css={`
                  @media screen and (max-width: 568px) {
                    display: none;
                  }
                `}
              />
              milestones that have shaped our brand.
            </DescriptionTwo>
            <ActionWrapper>
              <ButtonContainer>
                <Button round="true" to="/about" dark small>
                  Learn more
                </Button>
              </ButtonContainer>
            </ActionWrapper>
          </CardTwo>
        </Wrapper>
        <CardThree>
          <ImageWrapper>
            <TypeWriterWrapper>
              <HeaderTitleTypeWriterAccent>Expert</HeaderTitleTypeWriterAccent>
              <HeaderTitleTypeWriter>
                <Typewriter
                  options={{
                    strings: ["ASSISTANCE", "PUBLISHING", "MARKETING"],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </HeaderTitleTypeWriter>
              <HeaderTitleTypeWriterAccent>
                for Your Book’s Success
              </HeaderTitleTypeWriterAccent>
            </TypeWriterWrapper>

            {data.allFile.edges.map((image, key) => (
              <Image
                key={key}
                image={getImage(image.node.childImageSharp.gatsbyImageData)}
                alt="Writers Way Solutions Home Hero"
              />
            ))}
          </ImageWrapper>
        </CardThree>
      </Container>
    </>
  )
}

export default Hero

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 3rem calc((100vw - 1200px) / 2);
  display: flex;
  gap: 1.5rem;
  box-sizing: border-box;
  padding-top: 2.5rem;
  visibility: hidden;

  @media screen and (max-width: 1249px) {
    padding: 2rem 1.5rem;
    gap: 1rem;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (max-width: 568px) {
    padding: 1.5rem;
  }
`
const Wrapper = styled(Link)`
  display: ${({ isGrid }) => (isGrid ? "grid" : "flex")};
  flex-direction: ${({ isRow }) => (isRow ? "row" : "column")};
  gap: ${({ gap }) => (gap ? gap : "0")};
  grid-template-columns: 1fr 1fr;
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "auto")};

  @media screen and (max-width: 1249px) {
    gap: 1rem;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: ${({ hMobile }) => (hMobile ? hMobile : "100%")};
  }

  /* @media screen and (max-width: 568px) {
    height: 56dvh;
  } */
`

const CardThree = styled(Link)`
  border-radius: 20px;
  background: #9691ff;
  display: flex;
  height: auto;
  width: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: 81dvh;
  }

  @media screen and (max-width: 568px) {
    height: 56dvh;
  }
`

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-start;
  margin-top: 2rem;
`

const IconWrapper = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  justify-content: flex-start;
`

const CardOne = styled.div`
  background: #aeeeff;
  border: none;
  border-radius: 20px;
  height: ${({ height }) => (height ? height : "auto")};
  width: 100%;
  padding: 55px 40px;
  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: 568px) {
    padding: 25px 20px;
  }
`

const CardTwo = styled.div`
  background: #fdbe00;
  border: none;
  border-radius: 20px;
  height: ${({ height }) => (height ? height : "auto")};
  width: 100%;
  padding: 55px 40px;
  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: 568px) {
    padding: 25px 20px;
  }
`

const HeaderTitleNormal = styled.h4`
  font-size: 60px;
  font-family: ${props => props.theme.fonts.secondary} !important;
  color: #2d2f2f;
  letter-spacing: 0.8px;
  text-align: left;
  font-weight: 500;
  text-transform: none !important;

  @media screen and (max-width: 568px) {
    font-size: 35px;
  }
`
const HeaderTitleBig = styled.h4`
  font-size: 70px;
  font-family: ${props => props.theme.fonts.tertiary} !important;
  color: #fff;
  letter-spacing: 0.8px;
  text-align: left;
  @media screen and (max-width: 568px) {
    font-size: 50px;
  }
`

const HeaderTitleTwo = styled.h4`
  font-size: 35px;
  font-family: ${props => props.theme.fonts.secondary} !important;
  color: #fff;
  letter-spacing: 0.8px;
  text-align: left;
  font-weight: 600;

  @media screen and (max-width: 568px) {
    font-size: 25px;
  }
`

const HeaderTitleTypeWriter = styled.h5`
  font-size: 50px;
  font-family: ${props => props.theme.fonts.tertiary} !important;
  color: #fff;
  font-weight: 500;
  @media screen and (max-width: 568px) {
    font-size: 35px;
  }
  .Typewriter__wrapper {
    display: inline-flex; /* Make sure the text and cursor align properly */
    align-items: center; /* Vertically center the cursor */
    font-size: 50px;
    font-family: ${props => props.theme.fonts.tertiary} !important;
    color: #fff;
    letter-spacing: 0.8px;
    text-align: left;
    font-weight: 500;

    @media screen and (max-width: 568px) {
      font-size: 35px;
    }
  }
`

const HeaderTitleTypeWriterAccent = styled.h6`
  font-family: ${props => props.theme.fonts.secondary} !important;
  font-size: 20px;
  color: #fff;
  letter-spacing: 0.8px;
  text-align: left;
  font-weight: 400;

  @media screen and (max-width: 568px) {
    font-size: 14px;
  }
`

const TypeWriterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 5rem;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Description = styled.p`
  font-size: 14px;
  font-family: ${props => props.theme.fonts.secondary} !important;
  color: #69878f;
  font-weight: 400;
  text-align: justify;
  line-height: 22px;
  letter-spacing: 0.8px;

  @media screen and (max-width: 568px) {
    font-size: 12px;
  }
`

const DescriptionTwo = styled.p`
  font-size: 16px;
  font-family: ${props => props.theme.fonts.secondary} !important;
  color: #fff;
  font-weight: 400;
  text-align: justify;
  line-height: 22px;
  letter-spacing: 0.8px;
  margin-top: 0.7rem;

  @media screen and (max-width: 568px) {
    font-size: 12px;
  }
`

const ButtonContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 55px;
  gap: 1rem;
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #9691ff;
  border-radius: 20px;
  position: relative;
`
const Image = styled(GatsbyImage)`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 1024px) {
    width: 57%;
    left: 21%;
  }

  @media screen and (max-width: 568px) {
    width: 100%;
    object-fit: contain;
    height: auto;
    left: 3%;
  }
`

import React,{useRef,useEffect} from "react"
import {
  Container,
  Wrapper,
  Header,
  Card,
  Image,
  Title,
  Description,
  CardIcon,
  CardTitle,
  CardDescription,
  CardWrapper
} from "./AboutElements"
import svg from "../../../../assets/images/bookshelve2.svg"
import { FcCheckmark } from "@react-icons/all-files/fc/FcCheckmark"
import { FcIdea } from "@react-icons/all-files/fc/FcIdea"
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import { gsap } from "gsap"

gsap.registerPlugin(ScrollTrigger)

function About() {

  let container = useRef(null)

  useEffect(() => {
 
    const image = container.firstElementChild
    const content = container.lastElementChild

    const header = content.children[0]
    const cardOne = content.children[1]
    const cardTwo = content.children[2]

       gsap.to(container, { duration: 0, css: { visibility: "visible" } })

       let tl = new gsap.timeline({
         scrollTrigger:{
           trigger: container,
           start:"20px 80%",
           end:"+=350px",
           scrub:2,
         }
       })

       tl.from(
        [image],
        {
          duration: 1,
          opacity: 0,
          x: -50,
          ease: "power3.inOut"
        },
        "start"
      )

      tl.from(
        [header.children[0], header.children[1], cardOne.children[0],cardOne.children[1], cardTwo.children[0],cardTwo.children[1]],
        {
          duration: 1,
          opacity: 0,
          x: 50,
          ease: "power3.inOut",
          stagger: .15,
        },
        "start"
      )
 
 },[])
  return (
    <Container ref={el => container = el}>
      <Image src={svg} />
      <Wrapper>
        <Header>
          <Title>Writers Way Solutions ensures the client's success</Title>
          <Description>
          Writers Way Solutions prides itself on nurturing the creativity of its clients and maintaining close relationships.
          </Description>
        </Header>
        <Card>
          <CardIcon><FcIdea/></CardIcon>
          <CardWrapper>
          <CardTitle>Idea</CardTitle>
          <CardDescription>
          For writers who have compelling and untold stories. We work with writers to help achieve their goals through traditional publishing.  Over the years we have established ourselves as a home for best-selling fiction and non-fiction, and we continue to seek out unique talents.          
          </CardDescription>
          </CardWrapper>
        </Card>
        <Card>
          <CardIcon><FcCheckmark/></CardIcon>
          <CardWrapper>
          <CardTitle>Success</CardTitle>
          <CardDescription>
          We are committed to developing and maintaining our author's careers on an individual basis - managing their literary works as a whole and providing the services necessary to flourish on every level.
         </CardDescription>
          </CardWrapper>
        </Card>
      </Wrapper>
    </Container>
  )
}

export default About

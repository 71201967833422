import styled from "styled-components"

export const WcuContainer = styled.div`
  visibility:hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  ${"" /* align-items:center; */}
  padding: 4rem calc((100vw - 1300px) / 2);
  position:relative;
  z-index:3;
  ${'' /* top:-50px; */}
  ${'' /* margin-top: -80px; */}
  ${'' /* padding-bottom: 5rem; */}

    @media screen and (max-width: 1100px){
      margin-top: -30px;
    }

    @media screen and (max-width: 983px){
      margin-top: 0;
    }

    @media screen and (max-width: 768px) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
`
export const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  color: #111;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  overflow:hidden;
  gap: .5rem;

  @media screen and (max-width: 983px){
    text-align: start;
    justify-content:flex-start;
    align-items:flex-start;
  }

`
export const Title = styled.h3`
 font-size: clamp(3rem, 5vw, 2rem);
  color: ${props => props.theme.colors.accent5};
  padding: 0 2rem;
  font-weight: 700;
`
export const Description = styled.p`
  font-size: clamp(.7rem, 5vw, .9rem);
  padding: 0 2rem;
  ${'' /* color: ${props => props.theme.colors.dark2}; */}
  max-width:50%;
  ${'' /* line-height:1.5; */}
  color: ${props => props.theme.colors.dark2};
  ${'' /* font-size: clamp(0.5rem, 5vw, 0.9rem); */}
  line-height:1.5;
  @media screen and (max-width: 983px){
    max-width:100%
  }
`
export const Body = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding: 1.4rem;
  box-sizing: border-box;
  ${"" /* padding: 0 1rem; */}

  ${'' /* @media screen and (max-width: 983px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  } */}
`
export const Cards = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 2rem;
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
  padding: 2rem 1rem;
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  @media screen and (max-width: 983px){
    text-align: start;
    justify-content:flex-start;
    align-items:flex-start;
  }
`
export const CardIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
`
export const CardTitle = styled.p`
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.dark1};
  font-weight: 700;
`
export const CardDescription = styled.p`
  color: ${props => props.theme.colors.dark2};
  font-size: clamp(0.5rem, 5vw, 0.9rem);
  line-height:1.5;
  /* text-align: justify; */
`

import React, { useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import star from "../../../../assets/images/star.svg"
import { ImQuotesLeft } from "@react-icons/all-files/im/ImQuotesLeft"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from "gsap"

gsap.registerPlugin(ScrollTrigger)

const Testimonials = () => {
  let container = useRef(null)

  useEffect(() => {
    const header = container.children[0]
    const content = container.children[1]

    gsap.to(container, { duration: 0, css: { visibility: "visible" } })

    let tl = new gsap.timeline({
      scrollTrigger: {
        trigger: container,
        start: "20px 80%",
        end: "+=350px",
        scrub: 2,
      },
    })

    tl.from(
      [header],
      {
        duration: 1,
        opacity: 0,
        y: -50,
        ease: "power3.inOut",
        delay: 0.2,
        stagger: 0.15,
      },
      "start"
    )
    tl.from(
      [content],
      {
        duration: 1,
        opacity: 0,
        y: -60,
        ease: "power3.inOut",
        delay: 0.2,
        stagger: 0.15,
      },
      "start"
    )
  }, [])

  const data = useStaticQuery(graphql`
    query MyTestimonialsQuery {
      allTestimonialJson {
        edges {
          node {
            id
            description
            occupation
            name
            img {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  placeholder: BLURRED
                  transformOptions: { cropFocus: CENTER }
                  aspectRatio: 0.7
                )
              }
            }
          }
        }
      }
    }
  `)

  let settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
    // cssEase: "linear"
  }

  function getTestimonials(data) {
    const testimonialsArray = []

    data.allTestimonialJson.edges.forEach((item, index) => {
      testimonialsArray.push(
        <ContentWrapper key={index}>
          <FlexRow align={"center"}>
            <ImageWrapper>
              <ImageContainer>
                <Image image={getImage(item.node.img)} alt={item.node.name} />
              </ImageContainer>
            </ImageWrapper>
            <FlexCol gap={".5rem"}>
              <Details>
                <h6>{item.node.name}</h6>
                <p>Author</p>
              </Details>
              <FlexRow gap={".2rem"}>
                <ImageSvg src={star} alt="star" />
                <ImageSvg src={star} alt="star" />
                <ImageSvg src={star} alt="star" />
                <ImageSvg src={star} alt="star" />
                <ImageSvg src={star} alt="star" />
              </FlexRow>
            </FlexCol>
          </FlexRow>

          <Testimonial>
            <ImQuotesLeft
              css={`
                color: ${props => props.theme.colors.main1};
                font-size: 5rem;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                box-sizing: border-box;
                min-width: 20px;
                max-width: 20px;
                min-height: 23px;
                max-height: 23px;
              `}
            />
            <p>{item.node.description}</p>
          </Testimonial>
        </ContentWrapper>
      )
    })
    return testimonialsArray
  }
  return (
    <Container ref={el => (container = el)}>
      <Headers>
        <h3>Our client testimonials says it all</h3>
      </Headers>
      <Slider {...settings}>{getTestimonials(data)}</Slider>
    </Container>
  )
}

export default Testimonials

const Container = styled.div`
  visibility: hidden;
  ${
    "" /* background-image: 
    radial-gradient(rgb(132, 210, 260) 40.4%, transparent 40.4%),
    radial-gradient(rgb(132, 210, 260) 40.4%, transparent 40.4%);
  background-position: 0px 0px, 4px 4px;
  background-size: 8px 8px;
  background-color: rgb(132, 210, 246); */
  }
  padding: 5rem calc((100vw - 1200px) / 2);
  display: flex;
  flex-direction: column;
  ${"" /* justify-content:center; */}
  ${"" /* align-items:center; */}
  text-align:center;

  @media screen and (max-width: 768px) {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }

  .slick-slider {
    .slick-list {
      padding: 0 !important;
      .slick-track {
        display: flex !important;
        gap: 2rem;
        padding: 1rem;
        box-sizing: border-box;
      }
    }
  }
`

const Headers = styled.div`
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    text-align: start;
    margin-bottom: 2rem;
    font-size: clamp(3rem, 5vw, 2rem);
    font-weight: 700;
    color: ${props => props.theme.colors.accent5};
  }
`

const ContentWrapper = styled.div`
  padding: 2rem;
  box-sizing: border-box;
  display: flex !important;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  text-align: left;
  border-radius: 18px;
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
  min-height: 300px;
  max-height: 300px;
`

const FlexRow = styled.div`
  display: flex;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
  align-items: ${({ align }) => (align ? align : "flex-start")};
`
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
  align-items: ${({ align }) => (align ? align : "flex-start")};
`

const Testimonial = styled.div`
  margin-bottom: 2rem;
  display: flex;
  gap: 0.5rem;
  line-height: 1.5;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;

  @media screen and (max-width: 768px) {
    justify-content: center;
    text-align: center;
    gap: 0;
  }

  @media screen and (max-width: 450px) {
    padding: 0;
  }
  p {
    color: #444;
    font-size: clamp(0.5rem, 5vw, 0.8rem);
    font-weight: 500;

    margin-top: 20px;
    text-align: justify;
  }
`

const AuthorInfo = styled.div`
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 1rem;
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  ${"" /* margin-right: 2rem; */}
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    margin-right: 0;
    justify-content: center;
    text-align: center;
  }
  h6 {
    font-size: clamp(0.6rem, 5vw, 1rem);
    font-weight: 600;
    color: #666;
    ${"" /* margin-bottom: 0.5rem; */}
  }

  p {
    font-family: "Roboto", sans-serif;
    font-size: clamp(0.4rem, 5vw, 0.8rem);
    font-weight: 400;
    font-style: italic;
    color: ${props => props.theme.colors.dark2};
  }
`
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 1rem;
  }
`
const ImageContainer = styled.div`
  width: 4em;
  height: 4em;
  ${"" /* margin-right: 3rem; */}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
`

const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
`

const ImageSvg = styled.img`
  width: 22px;
  height: 22px;
  object-fit: contain;
  overflow: hidden;
`

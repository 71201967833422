import React,{useRef,useEffect} from "react"
import styled from "styled-components"
// import { useStaticQuery, graphql } from "gatsby"
// import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import barnes from "../../../../assets/images/b-and-noble.png"
import amazon from "../../../../assets/images/amazon-removebg-preview.png"
import huff from "../../../../assets/images/huff.png"
import abe from "../../../../assets/images/abebooks.png"
import franck from "../../../../assets/images/www3.png"
import alibris from "../../../../assets/images/alibris.png"
import audible from "../../../../assets/images/audible.png"
import betterworldbooks from "../../../../assets/images/betterworldbooks.png"
import indie from "../../../../assets/images/indie-bound.png"
import indigo from "../../../../assets/images/indigo.png"
import ape from "../../../../assets/images/mighty-ape.png"
import pacific from "../../../../assets/images/pacific-book-review.png"
import thriftbooks from "../../../../assets/images/thriftbooks.png"
import walmart from "../../../../assets/images/walmart-removebg-preview.png"
import water from "../../../../assets/images/water-stones.png"
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import { gsap } from "gsap"

gsap.registerPlugin(ScrollTrigger)

const TrustedComp = () => {
  let container = useRef(null)
  let image = useRef(null)

  useEffect(() => {
 
    const header = container.children[0]

    const imageOne = image
    // const imageTwo = imageOne.nextSibling
    // const imageThree = imageTwo.nextSibling 
    // const imageFour = imageThree.nextSibling
    // const imageFive = imageFour.nextSibling

       gsap.to(container, { duration: 0, css: { visibility: "visible" } })

       let tl = new gsap.timeline({
         scrollTrigger:{
           trigger: container,
           start:"20px 80%",
           end:"+=350px",
           scrub:2,
         }
       })

       tl.from(
        [header],
        {
          duration: 1,
          opacity: 0,
          y: 50,
          ease: "power3.inOut",
          delay: .2
        },
        "start"
      )
        tl.from(
          imageOne,
          {
            duration: 1,
            opacity: 0,
            y: 50,
            ease: "power3.inOut",
            stagger: .15
          },
          "start"
        ).from(
          imageOne,
          {duration: 2, scale: 1.2,  ease: "power3.inOut"}, .5)
      // .from(
      //   imageTwo,
      //   {
      //     duration: 1,
      //     opacity: 0,
      //     y: 50,
      //     ease: "power3.inOut",
      //   },
      //   .2
      // ).from(imageTwo.firstElementChild,{duration: 2, scale: 1.6,  ease: "power3.inOut"}, .2)
      // .from(
      //   imageThree,
      //   {
      //     duration: 1,
      //     opacity: 0,
      //     y: 50,
      //     ease: "power3.inOut",
      //   },
      //   .2
      // ).from(imageThree.firstElementChild,{duration: 2, scale: 1.6,  ease: "power3.inOut"}, .2)
      // .from(
      //   imageFour,
      //   {
      //     duration: 1,
      //     opacity: 0,
      //     y: 50,
      //     ease: "power3.inOut",
      //   },
      //   .2
      // ).from(imageFour.firstElementChild,{duration: 2, scale: 1.6,  ease: "power3.inOut"}, .2)
      // .from(
      //   imageFive,
      //   {
      //     duration: 1,
      //     opacity: 0,
      //     y: 50,
      //     ease: "power3.inOut",
      //   },
      //   .2
      // ).from(imageFive.firstElementChild,{duration: 2, scale: 1.6,  ease: "power3.inOut"}, .2)
 
 },[])
  return (
    <MainContainer ref={el => container = el}>
      <Header>
        <Title>Trusted companies</Title>
      </Header>

      <Container ref={el => image = el}>
         <ImageContainer href="https://www.barnesandnoble.com" target="_blank" rel="noopener noreferrer">
          <Image src={barnes}/>
        </ImageContainer>
        <ImageContainer href="https://www.amazon.com" target="_blank" rel="noopener noreferrer">
          <Image src={amazon} />
        </ImageContainer>
        <ImageContainer href="https://www.huffpost.com" target="_blank" rel="noopener noreferrer">
          <Image src={huff} />
        </ImageContainer>
        <ImageContainer href="https://www.abebooks.com/" target="_blank" rel="noopener noreferrer">
          <Image src={abe} />
        </ImageContainer>
        <ImageContainer href="https://www.buchmesse.de/en" target="_blank" rel="noopener noreferrer">
          <Image src={franck} />
        </ImageContainer> 

        <ImageContainer href="https://www.alibris.com/" target="_blank" rel="noopener noreferrer">
          <Image src={alibris}/>
        </ImageContainer>
        <ImageContainer href="https://www.audible.com/" target="_blank" rel="noopener noreferrer">
          <Image src={audible} />
        </ImageContainer>
        <ImageContainer href="https://www.betterworldbooks.com/" target="_blank" rel="noopener noreferrer">
          <Image src={betterworldbooks} />
        </ImageContainer>
        <ImageContainer href="https://www.indiebound.org/" target="_blank" rel="noopener noreferrer">
          <Image src={indie} />
        </ImageContainer>
        <ImageContainer href="https://www.indigo.ca/en-ca/" target="_blank" rel="noopener noreferrer">
          <Image src={indigo} />
        </ImageContainer>
        <ImageContainer href="https://www.mightyape.co.nz/" target="_blank" rel="noopener noreferrer">
          <Image src={ape}/>
        </ImageContainer>
        <ImageContainer href="https://www.pacificbookreview.com/" target="_blank" rel="noopener noreferrer">
          <Image src={pacific} />
        </ImageContainer>
        <ImageContainer href="https://www.thriftbooks.com/" target="_blank" rel="noopener noreferrer">
          <Image src={thriftbooks} />
        </ImageContainer>
        <ImageContainer href="https://www.walmart.com/" target="_blank" rel="noopener noreferrer">
          <Image src={walmart} />
        </ImageContainer>
        <ImageContainer href="https://www.waterstones.com/" target="_blank" rel="noopener noreferrer">
          <Image src={water} />
        </ImageContainer>
      </Container>
    </MainContainer>
  )
}

export default TrustedComp

const MainContainer = styled.div`
  visibility:hidden;
  padding: 5rem calc((100vw - 1200px) / 2);
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;

  @media screen and (max-width: 1249px) {
    padding: 2rem 1.5rem;
    gap: 1rem;
  }

  @media screen and (max-width: 768px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`
const Header = styled.div`
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const Title = styled.h3`
  font-size: clamp(3rem, 5vw, 2rem);
  color: ${props => props.theme.colors.accent5};
  padding: 0 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
`

const Container = styled.div`
    display: grid;
  grid-gap: 30px;
  ${'' /* grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); */}
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));  
  ${'' /* @media screen and (max-width: 943px) {
    grid-template-columns: repeat(2, 1fr);
  } */}
  @media screen and (max-width: 768px) {
    gap: 10px;
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); 
  }
  ${'' /* display: flex;
  justify-content: space-between;
  gap: 2rem; */}
`

const ImageContainer = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 1rem 0;
  ${'' /* width: 100%; */}
  background: #FFF;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-sizing: border-box;
  border-radius: 20px;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid ${props => props.theme.colors.accent3};
    transition: border ease-in-out .3s;
  }
  @media screen and (max-width: 420px) {
    width: 100%;
  }
`
const Image = styled.img`
  width: 50%;
  aspect-ratio: 3/2;
  object-fit: contain;

  ${'' /* &:hover {
    margin-top: -1rem;
    transition: margin ease-in-out .3s;
  } */}

  @media screen and (max-width: 768px) {
    width: 45%;
  }
`

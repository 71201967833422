import * as React from "react"
import Seo from "../components/seo"
import Wcu from "../components/sections/home/wcu/Wcu"
import About from "../components/sections/home/about/About"
import Testimonials from "../components/sections/home/testimonial/Testimonials"
import HeroMain from "../components/sections/home/mainHero/HeroMain"
// import NewsLetter from "../components/sections/home/newsLetter/NewsLetter"
import TrustedComp from "../components/sections/home/trustedComp/TrustedComp"
import Services from "../components/sections/home/service/Services"
import { Helmet } from "react-helmet"

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <Helmet bodyAttributes={{ class: "header-class" }} />
    <HeroMain />
    <Wcu />
    <About />
    <Testimonials />
    <Services />
    <TrustedComp />
    {/* <NewsLetter/> */}
  </>
)

export default IndexPage
